.testimonial-item {
  padding-bottom: 30px;
  text-align: center;

  @media screen and (min-width: 992px) {
    padding-bottom: 50px;
  }

  .testimonial-item-content {
    margin-bottom: 25px;
    font-family: "BigCaslon W05 Roman";
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;

    @media screen and (min-width: 992px) {
      margin-bottom: 50px;
      font-size: 40px;
      line-height: 57px;
    }
  }

  .testimonial-item-caption {
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;

    @media screen and (min-width: 992px) {
      font-size: 23px;
      line-height: 29px;
    }
  }
}
