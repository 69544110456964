.box {
  padding: 30px 30px 40px;

  &.box-dashed-gray {
    border: 1px solid $gray-mid;
  }

  &.box-dashed-primary {
    border: 1px solid $primary;
  }

  &.box-primary {
    color: $white !important;
    background-color: $primary;

    a {
      color: inherit;
    }
  }

  &.box-secondary {
    background-color: $secondary;
  }

  &.box-gray {
    background-color: $gray-light;
  }

  &.box-white {
    color: $gray-dark !important;
    background-color: $white;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .box-contact-details {
    margin-bottom: 30px;

    p {
      line-height: 32px;
    }
  }

  .box-title {
    text-transform: unset;
  }
}

.boxes {
  list-style: none;
  margin: -25px;
  padding-left: 0;
  overflow: hidden;

  li {
    float: left;
    width: 100%;
    padding: 25px;

    @media screen and (min-width: 768px) {
      width: 50%;
    }

    @media screen and (min-width: 992px) {
      width: 33%;
    }
  }
}
