//Buttons
.btn {
  padding: 0;
  font-weight: 300;
  text-align: left;
  border: none;
  border-radius: 0;

  &.btn-primary,
  &.btn-secondary {
  }

  &.btn-primary {
    position: relative;
    padding-right: 44px;
    color: $gray-dark;
    font-size: 18px;
    line-height: 26px;
    background-color: transparent;

    @media screen and (min-width: 992px) {
      padding-bottom: 4px;
      padding-right: 60px;
      font-size: 23px;
      line-height: 29px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 35px;
      height: 13px;
      bottom: 9px;
      right: 0;
      background-image: url('#{$image-base-uri}naa-arrow-right-primary.svg');
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 35px;
      transition: all 0.3s ease-out;

      @media screen and (min-width: 992px) {
        width: 44px;
        height: 16px;
        background-size: 44px;
      }
    }

    &:hover,
    &:active {
      &:after {
        right: -4px;
        transition: all 0.3s ease-out;
      }
    }

    &.btn-light {
      color: $white;

      &:after {
        background-image: url('#{$image-base-uri}naa-arrow-right-white.svg');
      }
    }
  }

  &.btn-secondary {
    &:hover,
    &:focus,
    &:active {
    }
  }

  &.btn-collapse,
  &.btn-download {
    position: relative;
    padding: 0 30px 3px 0;
    color: $gray-dark;
    font-size: 15px;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: right bottom 6px;
    background-size: 16px;
  }

  &.btn-collapse {
    background-image: url('#{$image-base-uri}naa-chevron-dropup-primary.svg');

    &.collapsed {
      background-image: url('#{$image-base-uri}naa-chevron-dropdown-primary.svg');
    }

    &:hover,
    &:focus,
    &:active {
    }
  }

  &.btn-download {
    background-image: url('#{$image-base-uri}naa-chevron-download-primary.svg');
    background-position: right bottom 4px;

    &:hover,
    &:focus,
    &:active {}
  }

  &+.btn {
    margin-left: 50px;
  }

  &.btn-empty {
    height: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}

button {
  &:focus {
    outline: none;
  }
}
