.mb-6 {
  margin-bottom: 4rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.pt-25 {
  padding-top: 0.75rem !important;
}

.pb-25 {
  padding-bottom: 0.75rem !important;
}

@for $i from 0 through 20 {
  .mtc-#{$i * 15} {
    margin-top: $i * 15px !important;
  }
}

@for $i from 0 through 20 {
  .mtc-md-#{$i * 15} {
    @media screen and (min-width: 768px) {
      margin-top: $i * 15px !important;
    }
  }

  .mtc-lg-#{$i * 15} {
    @media screen and (min-width: 992px) {
      margin-top: $i * 15px !important;
    }
  }

  .mtc-xl-#{$i * 15} {
    @media screen and (min-width: 1200px) {
      margin-top: $i * 15px !important;
    }
  }
}

@for $i from 0 through 20 {
  .mbc-#{$i * 15} {
    margin-bottom: $i * 15px !important;
  }
}

@for $i from 0 through 20 {
  .mbc-md-#{$i * 15} {
    @media screen and (min-width: 768px) {
      margin-bottom: $i * 15px !important;
    }
  }

  .mbc-lg-#{$i * 15} {
    @media screen and (min-width: 992px) {
      margin-bottom: $i * 15px !important;
    }
  }

  .mbc-xl-#{$i * 15} {
    @media screen and (min-width: 1200px) {
      margin-bottom: $i * 15px !important;
    }
  }
}

@for $i from 0 through 20 {
  .ptc-#{$i * 15} {
    padding-top: $i * 15px !important;
  }
}

@for $i from 0 through 20 {
  .ptc-md-#{$i * 15} {
    @media screen and (min-width: 768px) {
      padding-top: $i * 15px !important;
    }
  }

  .ptc-lg-#{$i * 15} {
    @media screen and (min-width: 992px) {
      padding-top: $i * 15px !important;
    }
  }

  .ptc-xl-#{$i * 15} {
    @media screen and (min-width: 1200px) {
      padding-top: $i * 15px !important;
    }
  }
}

@for $i from 0 through 20 {
  .pbc-#{$i * 15} {
    padding-bottom: $i * 15px !important;
  }
}

@for $i from 0 through 20 {
  .pbc-md-#{$i * 15} {
    @media screen and (min-width: 768px) {
      padding-bottom: $i * 15px !important;
    }
  }

  .pbc-lg-#{$i * 15} {
    @media screen and (min-width: 992px) {
      padding-bottom: $i * 15px !important;
    }
  }

  .pbc-xl-#{$i * 15} {
    @media screen and (min-width: 1200px) {
      padding-bottom: $i * 15px !important;
    }
  }
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.hideIt {
  height: 0;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;

  * {
    height: 0;
    margin: 0 !important;
    padding: 0 !important;
  }
}

*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.spam-protection {
  &--at:before {
    content: '@';
  }

  &--dot:before {
    content: '.';
  }

  &__inner {
    display: inline-block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}
