.form-control {
  padding: 10px 25px;
  color: $gray-mid;
  font-size: 12px;
  line-height: 20px;
  border-color: $white;
  border-radius: 4px;

  @media screen and (min-width: 992px) {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 13px;
  }

  &.outline {
    border-color: $gray-dark;
  }
}

.form-select {
  &:focus {
    box-shadow: none;
  }

  &.quick-access {
    padding: 32px 40px 35px;
    color: $gray-dark;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    background-image: url('#{$image-base-uri}naa-chevron-dropdown-primary.svg');
    background-repeat: no-repeat;
    background-position: right 44px center;
    background-size: 16px;
    border: 2px solid $gray-dark;

    @media screen and (min-width: 992px) {
      padding: 29px 40px 37px;
      font-size: 23px;
      line-height: 30px;
    }
  }
}

.form-check {
  padding-left: 1.75rem;
  
  .form-check-input {
    margin-left: -1.75rem;
  }
}

.form-check-input {
  margin-top: 10px;
  border-width: 2px;
  border-color: $gray-dark;

  &:focus {
    box-shadow: none;
  }

  &:checked {
    background-color: $white;
    border-color: $primary;

    &[type=radio] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FF694F'/%3e%3c/svg%3e");
    }
  }
}

.form-check-label {
  font-size: 23px;
  font-weight: 300;
  line-height: 29px;
}

.form-check-inline {
  margin-right: 50px;
}

::placeholder {
  color: $gray-mid !important;
  font-weight: 300;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $gray-mid !important;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: $gray-mid !important;
  font-weight: 300;
}
