.check-list,
.tag-list,
.questions-list,
.classic-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}


.check-list {
  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;

    &:before {
      content: '';
      float: left;
      width: 16px;
      height: 16px;
      margin-top: 11px;
      margin-left: -30px;
      margin-right: 14px;
      background-image: url('#{$image-base-uri}naa-icon-check-black.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 16px;
    }
  }

  &.primary {
    li {
      &:before {
        background-image: url('#{$image-base-uri}naa-icon-check-primary.svg');
      }
    }
  }

  &.white {
    li {
      &:before {
        background-image: url('#{$image-base-uri}naa-icon-check-white.svg');
      }
    }
  }

  &.big {
    li {
      font-size: 16px;
      line-height: 20px;

      @media screen and (min-width: 992px) {
        font-size: 22px;
        line-height: 34px;
      }

      &+li {
        margin-top: 30px;

        @media screen and (min-width: 992px) {
          margin-top: 50px;
        }
      }
    }
  }
}

.tag-list {
  display: flex;
  flex-flow: row wrap;

  li {
    margin-right: 15px;
    margin-bottom: 20px;
    padding: 11px 15px 14px;
    color: $white;
    font-size: 13px;
    line-height: 13px;
    background-color: $primary;
    border-radius: 4px;

    @media screen and (min-width: 768px) {
      padding: 14px 20px 17px;
      font-size: 14px;
      line-height: 14px;
    }

    @media screen and (min-width: 992px) {
      padding: 18px 25px 21px;
      font-size: 15px;
      line-height: 15px;
      margin-right: 25px;
      margin-bottom: 30px;
    }
  }
}

.link-list {
  .link-list-item {
    a {
      display: block;
      font-size: 20px;
      line-height: 29px;
      padding-right: 60px;
      background-image: url('#{$image-base-uri}naa-arrow-right-primary.svg');
      background-repeat: no-repeat;
      background-position: right 4px center;
      background-size: 44px;
      transition: all 0.3s ease-out;

      @media screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 32px;
      }

      @media screen and (min-width: 992px) {
        font-size: 30px;
        line-height: 39px;
      }

      &:hover {
        background-position: right 0 center;
        transition: all 0.3s ease-out;
      }
    }

    &+.link-list-item {
      margin-top: 15px;

      @media screen and (min-width: 992px) {
        margin-top: 30px;
      }
    }
  }

  &.link-list-big {
    .link-list-item {
      padding-top: 30px;
      padding-bottom: 35px;

      @media screen and (min-width: 992px) {
        padding-top: 71px;
        padding-bottom: 80px;
      }

      &+.link-list-item {
        margin-top: 0;
      }
    }
  }
}

.questions-list {
  li {
    &+li {
      margin-top: 75px;
    }
  }

  .questions-list-inner {
    display: flex;
    flex-flow: row nowrap;

    .questions-list-inner-left {
      min-width: 55px;

      @media screen and (min-width: 768px) {
        min-width: 75px;
      }

      @media screen and (min-width: 992px) {
        min-width: 110px;
      }

      .questions-list-number {
        font-size: 60px;
        line-height: 33px;

        @media screen and (min-width: 992px) {
          font-size: 93px;
          line-height: 46px;
        }
      }
    }

    .questions-list-inner-right {
      p {
        margin-bottom: 0;
      }
    }
  }

  .questions-list-title {
    text-transform: unset;
  }
}

.classic-list {
  li {
    position: relative;

    &:before {
      content: '\2013';
      float: left;
      width: 10px;
      height: 16px;
      margin-right: 8px;
    }

    &+li {
      margin-top: 10px;
    }
  }
}
