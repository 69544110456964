.rechtsgebiete {
  .intro {
    .teaser1 {
      position: relative;

      @media screen and (min-width: 768px) {
        top: -135px;
      }

      @media screen and (min-width: 992px) {
        top: -215px;
      }
    }
  }
}
