html,
body {

}

html {
  min-height: 100vh;
}

body {
  color: $gray-dark;
  font-family: "Sofia";
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
  overflow-x: hidden;

  @media screen and (min-width: 992px) {
    font-size: 15px;
    line-height: 26px;
  }

  &.full-height {
    display: flex;
    flex-flow: column wrap;
    min-height: 100vh;

    main {
      display: flex;
      flex-flow: column wrap;
      flex-grow: 1;
    }

    footer {
      margin-top: auto;
    }
  }
}

main {
  overflow-x: hidden;
}
