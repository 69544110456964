footer {
  padding-top: 75px;

  @media screen and (min-width: 992px) {
    padding-top: 150px;
  }

  .footer-logo {
    a {
    }
  }

  p {
  }

  a {
  }

  .nav-footer {
    margin-bottom: 75px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: 100px;
    }

    .nav-item {
      .nav-link {
        padding: 10px 30px;
        color: $gray-dark;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        @media screen and (min-width: 768px) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      &.active,
      &:hover,
      &:focus {
        .nav-link {
          color: $primary;
        }
      }
    }
  }

  .nav-social {
    margin-bottom: 75px;

    @media screen and (min-width: 992px) {
      margin-bottom: 100px;
    }

    .nav-item {
      .nav-link {
        width: 54px;
        height: 30px;
        padding: 0 12px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 30px;
      }

      &.linkedin {
        .nav-link {
          background-image: url('#{$image-base-uri}naa-icon-linkedin.svg');
        }
      }

      &.mail {
        .nav-link {
          background-image: url('#{$image-base-uri}naa-icon-mail.svg');
        }
      }
    }
  }

  .bottom {
    padding-top: 10px;
    padding-bottom: 18px;
    color: $white;
    text-align: center;
    background-color: $primary;
  }
}
