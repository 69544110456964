@font-face {
  font-family: "Sofia";
  src: url("#{$font-base-uri}6080392/1fc28cb8-2c23-4db3-9bae-3e8489ae6bd4.woff2") format("woff2"),
    url("#{$font-base-uri}6080392/a15cc54b-11a7-4628-bd1f-f0c9ccc1fcd7.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Sofia";
  src: url("#{$font-base-uri}6080398/4a6cb1fb-207c-45f0-b957-12530e1c9444.woff2") format("woff2"),
    url("#{$font-base-uri}6080398/9cf72f98-dafd-4b8a-ae89-de91ad1e9c63.woff") format("woff");
  font-size: 400;
}

@font-face {
  font-family: "Sofia";
  src: url("#{$font-base-uri}6080407/e4ac10c9-913b-4dd7-b65a-25667db020bd.woff2") format("woff2"),
    url("#{$font-base-uri}6080407/6c3cd16b-a28c-45c9-8a09-1e5682202730.woff") format("woff");
  font-size: 500;
}

@font-face {
  font-family: "Bodoni Moda";
  src: url("#{$font-base-uri}Bodoni_Moda/BodoniModa_28pt-Regular.ttf") format("truetype");
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  text-shadow: #fff 0px 1px 1px;
}

@font-face {
  font-family: "BigCaslon W05 Roman";
  src: url("#{$font-base-uri}6205162/cdd7aadf-32b7-46f4-8e57-6742c3104109.woff2") format("woff2"), url("#{$font-base-uri}6205162/26267dbf-2717-4729-9480-2187a956ee0b.woff") format("woff");
}


.font-standard {
  font-family: "Sofia" !important;
}

.font-serif {
  font-family: "Bodoni Moda" !important;
}
