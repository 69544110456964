.benefits {
  display: flex;
  flex-flow: row wrap;
  margin-left: -25px;
  margin-right: -25px;

  .benefit-item {
    width: 50%;
    margin-bottom: 30px;
    padding-left: 25px;
    padding-right: 25px;

    @media screen and (min-width: 992px) {
      width: 25%;
      margin-bottom: 50px;
    }

    .benefit-item-inner {
      padding-left: 30px;
      font-size: 16px;
      line-height: 20px;

      @media screen and (min-width: 992px) {
        font-size: 22px;
        line-height: 34px;
      }

      &:before {
        content: "";
        float: left;
        width: 16px;
        height: 16px;
        margin-left: -30px;
        margin-right: 14px;
        margin-top: 3px;
        background-image: url('#{$image-base-uri}naa-icon-check-black.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 16px;

        @media screen and (min-width: 992px) {
          margin-top: 11px;
        }
      }
    }
  }

  &.benefits-primary {
    .benefit-item {
      .benefit-item-inner {
        &:before {
          background-image: url('#{$image-base-uri}naa-icon-check-primary.svg');
        }
      }
    }
  }
}