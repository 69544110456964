section {
  &.line1 {
    height: 170px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media screen and (min-width: 576px) {
      height: 250px;
    }

    @media screen and (min-width: 768px) {
      height: 320px;
    }

    @media screen and (min-width: 992px) {
      height: 420px;
    }

    @media screen and (min-width: 1200px) {
      height: 480px;
    }

    @media screen and (min-width: 1400px) {
      height: 570px;
    }
  }
}
