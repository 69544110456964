body {
  &.home {
    header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 2;
    }
  }
}

.navbar-bwbt {
  padding-top: 63px;
  padding-bottom: 36px;

  .navbar-brand {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;
    width: 156px;
    height: 83px;
    margin-left: 25px;
    margin-right: 0;
    background-image: url('#{$image-base-uri}naa-logo.svg');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 156px;

    @include media-breakpoint-up(sm) {
      padding-top: 34px;
    }

    @media screen and (min-width: 992px) {
      margin-left: 70px;
    }
  }
}

.navbar-collapse {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -80%;
  height: 100vh;
  width: 80%;
  background-color: $gray-light;
  transition: all 0.4s ease-in-out;
  z-index: 2;

  @media screen and (min-width: 576px) {
    right: -430px;
    width: 430px;
  }

  &.show {
    right: 0;
    transition: all 0.4s ease-in-out;
  }
}

.collapse-component {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;

  .collapsing {
    width: 80%;
    height: auto;
    overflow: hidden;
    transition: all .35s ease;

    @media screen and (min-width: 576px) {
      width: 430px;
    }
  }

  &.shown {
    background-color: $overlay;
    transition: all 0.4s ease-in-out;
    z-index: 1;
    pointer-events: visible;
  }
}

.navbar-toggler {
  display: inline-block;
  position: absolute;
  top: 89px;
  right: 25px;
  padding: 4px;
  z-index: 7;
  pointer-events: visible;
  border: none;

  @include media-breakpoint-up(sm) {
    top: 60px;
  }

  @include media-breakpoint-up(lg) {
    right: 100px;
  }

  .navbar-toggler-icons {
    position: relative;
    width: 28px;
    height: 16px;
    transform: rotate(0deg);
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      background-color: $black;
      border-radius: 4px;
      opacity: 1;
      right: 0;
      transform: rotate(0deg);
      transition: all 0.4s ease-in-out;

      &:nth-child(1) {
        top: 0px;
        width: 15px;
      }

      &:nth-child(2) {
        top: 14px;
        width: 28px;
      }

    }
  }

  &.toggler-white {
    .navbar-toggler-icons {
      span {
        background-color: $white;
      }
    }
  }

  &:not(.collapsed) {
    span {
      &:nth-child(1) {
        top: 8px;
        width: 28px;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        top: 8px;
        transform: rotate(-135deg);
      }
    }
  }
}

body {
  &.home {
    .navbar-bwbt {
      .navbar-brand {
        background-image: url('#{$image-base-uri}naa-logo-white.svg');
      }

      .navbar-toggler {
        &.collapsed {
          .navbar-toggler-icons {
            span {
              background-color: $white;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .forms-link,
      .language-switcher ul,
      .language-switcher a {
        color: $white;
      }
    }
  }
}

.navbar-main {
  position: relative;
  height: 100vh;
  z-index: 3;

  >.nav-item {
    >.nav-link {
      position: relative;
      color: $gray-dark;
      padding: 18px 50px;
      font-size: 18px;
      line-height: 26px;
      transition: all 0.3s ease-in-out;

      @media screen and (min-width: 576px) {
        font-size: 23px;
        line-height: 29px;
      }

      &.show {
        &:hover {
          color: rgba($white, 0.65) !important;
        }

        &:before {
          background-color: $primary;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &:last-child {
      >.nav-link {
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          width: 100%;
          height: 100vh;
          transition: all 0.3s ease-in-out;
          z-index: -1;
        }
      }
    }

    &.active,
    &:hover,
    &:focus {
      >.nav-link {
        color: rgba($gray-dark, 0.4);
      }
    }

    &:first-child {
      >.nav-link {
        padding-top: 87px;

        @include media-breakpoint-up(sm) {
          padding-top: 58px;
        }
      }
    }
  }
}

.dropdown-submenu {
  position: relative;

  .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }

  .dropdown-menu-right {
    left: auto;
    right: 100%;
  }
}

.dropdown-toggle,
.dropdown-submenu-toggle {
  &:after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 6px;
    margin-bottom: 4px;
    background-image: url('#{$image-base-uri}naa-chevron-dropdown-primary.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px;
    vertical-align: bottom;
    border: 0;
    transition: all 0.3s ease-in-out;
  }

  &.show {
    color: $white !important;
    background-color: $primary;
  }

  &.show {
    &:after {
      background-image: url('#{$image-base-uri}naa-chevron-dropup-white.svg') !important;
      transition: all 0.3s ease-in-out;
    }
  }
}

.dropdown-submenu-toggle {
  &:after {
    margin-bottom: -1px;
  }
}

.dropdown-item {
  padding: 17px 50px;
  font-size: 13px;
  line-height: 15px;

  @media screen and (min-width: 576px) {
    font-size: 15px;
    line-height: 15px;
  }

  &.active,
  &:hover,
  &:focus {
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }

  &.active,
  &:hover {
    color: rgba($white, 0.65) !important;
  }
}

.dropdown-menu {
  display: block;
  min-width: auto;
  height: 0;
  margin: 0 !important;
  padding: 0;
  color: $gray-dark;
  font-size: 15px;
  line-height: 15px;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.show {
    height: auto;
    padding-bottom: 40px;
    background-color: $primary;
    overflow: visible;

    .dropdown-toggle,
    .dropdown-submenu-toggle {
      &:after {
        background-image: url('#{$image-base-uri}naa-chevron-dropdown-white.svg');
      }
    }

    .dropdown-item {
      color: $white;
    }
  }

  li {
    @media screen and (min-width: 992px) {
      position: relative;
    }
  }

  >li {
    &:hover {
      >.submenu {
        @media screen and (min-width: 992px) {
          display: block;
        }
      }
    }
  }

  .dropdown-menu {
    @media screen and (max-width: 991px) {
      margin-left: 0.7rem;
      margin-right: 0.7rem;
      margin-bottom: .5rem;
    }
  }
}

.dropdown-menu {
  &.dropdown-submenu {
    .dropdown-item {
      padding: 17px 75px;
    }
  }
}

.language-switcher {
  position: absolute;

  ul {
    font-size: 17px;
    line-height: 29px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;

    &:not(:last-child):after {
      content: ' / ';
    }

    strong {
      font-weight: 300;
    }

    a {
      opacity: 0.3;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    background-color: $gray-light;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 25px;
    text-align: right;
  }

  @include media-breakpoint-up(sm) {
    right: 105px;
    top: 55px;

    ul {
      font-size: 23px;
    }
  }

  @include media-breakpoint-up(lg) {
    right: 220px;
  }
}

.forms-link {
  position: absolute;
  font-size: 17px;
  line-height: 29px;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    top: 0;
    left: 25px;
    z-index: 1;
  }

  @include media-breakpoint-up(sm) {
    right: 232px;
    top: 55px;
    font-size: 23px;
  }

  @include media-breakpoint-up(lg) {
    right: 384px;
  }
}
