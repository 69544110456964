h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
}

.h0 {
}

h1,
.h1,
h2,
.h2 {
}

h1,
.h1 {
  font-family: "BigCaslon W05 Roman";
  font-size: 43px;
  font-weight: 500;
  line-height: 46px;

  &:not(.text-center) {
    text-transform: uppercase;
  }

  @media screen and (min-width: 992px) {
    font-size: 93px;
    line-height: 130px;
  }
}

.h1-special {
  margin-top: -15px;
  margin-bottom: 16px;
  font-size: 48px;
  font-weight: 300;
  line-height: 54px;

  @media screen and (min-width: 992px) {
    margin-top: -30px;
    margin-bottom: 50px;
    font-size: 115px;
    line-height: 110px;
  }
}

h2,
.h2 {
  margin-bottom: 50px;
  font-size: 34px;
  line-height: 38px;

  @media screen and (min-width: 992px) {
    font-size: 70px;
    line-height: 78px;
  }
}

h3,
.h3 {
  margin-bottom: 16px;
  font-family: "BigCaslon W05 Roman";
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;

  &:not(.text-center) {
    text-transform: uppercase;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 39px;
  }
}

h4,
.h4 {
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;

  @media screen and (min-width: 992px) {
    font-size: 22px;
    line-height: 34px;
  }
}

h5,
.h5 {
}

h6,
.h6 {
}

p {
  margin-bottom: 30px;

  &:last-of-type {
  }
}

b,
strong {
}
