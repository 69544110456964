.timeline-intro-image {
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 45px;
  }

  @media screen and (min-width: 992px) {
    width: 40%;
    margin-left: auto;
    margin-top: -300px;
  }

  @media screen and (min-width: 1200px) {
    width: 100%;
    margin-top: -250px;
    margin-bottom: 0;
  }
}

.timeline-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;

  @media screen and (min-width: 1200px) {
    align-items: center;
  }

  .timeline-date,
  .timeline-details {
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (min-width: 992px) {
      padding-top: 52px;
      padding-bottom: 52px;
    }
  }

  .timeline-date {
    display: flex;
    align-items: center;
    position: relative;
    min-width: 40%;
    max-width: 40%;
    font-family: "BigCaslon W05 Roman";
    font-size: 43px;
    font-weight: 500;
    line-height: 49px;
    border-right: 1px solid $primary;

    @media screen and (min-width: 992px) {
      min-width: 210px;
      max-width: 210px;
      font-size: 72px;
      line-height: 90px;
    }

    @media screen and (min-width: 1200px) {
      min-width: 280px;
      max-width: 280px;
      font-size: 93px;
      line-height: 130px;
    }
  }

  .timeline-details {
    padding-left: 30px;

    @media screen and (min-width: 992px) {
      padding-left: 55px;
    }
  }

  &:first-child,
  &:last-child {
    .timeline-date {
      &:before {
        content: '';
        position: absolute;
        right: -5px;
        width: 8px;
        height: 8px;
        background-color: $primary;
        border-radius: 50%;
      }
    }
  }

  &:first-child {
    .timeline-date {
      &:before {
        top: -5px;
      }
    }
  }

  &:last-child {
    .timeline-date {
      &:before {
        bottom: -5px;
      }
    }
  }

  &.inactive {
    color: $gray-mid2;

    .timeline-date {
      border-color: $gray-mid2;
    }

    &:first-child,
    &:last-child {
      .timeline-date {
        &:before {
          background-color: $gray-mid2;
        }
      }
    }
  }
}
