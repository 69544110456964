.content-element {
  border-bottom: 1px solid $gray-mid;
}

.banner-element {
  color: $white;
  background-color: $primary;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
  }

  .banner-element-title {
    text-transform: unset;
  }

  &.banner-text-image {
    .banner-element-left {
      img {
        width: 100%;
        align-self: center;
        flex: 0 0 auto;
      }

      @media screen and (min-width: 1200px) {
        flex: 0 0 40%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .banner-element-right {
      padding: 45px;

      @media screen and (min-width: 1200px) {
        padding: 30px 30px 30px;
      }

      @media screen and (min-width: 1400px) {
        padding: 45px 45px 45px;
      }

      @media screen and (min-width: 1600px) {
        padding: 55px 50px 55px;
      }
    }
  }

  &.banner-teaser {
    flex-flow: column wrap;
    padding: 60px 0;
    color: $gray-dark;
    text-align: center;

    @media screen and (min-width: 992px) {
      padding: 100px 0;
    }

    .banner-teaser-content {
      padding-left: 30px;
      padding-right: 30px;

      @media screen and (min-width: 992px) {
        padding-left: 150px;
        padding-right: 150px;
      }
    }
  }

  &.banner-primary {
    color: $white;
    background-color: $primary;

    .btn {
      &.btn-primary {
        &:after {
          background-image: url('#{$image-base-uri}naa-arrow-right-white.svg');
        }
      }
    }
  }

  &.banner-secondary {
    background-color: $secondary;
  }

  &.banner-gray {
    background-color: $gray-light;
  }
}

.banner-teasers {
  padding-bottom: 150px;
}

.teaser-element {
  &.teaser-text {
    h1 {
      margin-bottom: 50px;
    }
  }

  &.teaser-text-image {
    img {
      margin-bottom: 30px;
    }
  }
}

.member-element {
  display: block;
  margin-bottom: 100px;
  color: $gray-dark;
  text-decoration: none;

  .member-element-image {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .member-element-title {
    margin-bottom: 0;
    text-align: center;
    text-transform: unset;
  }

  &:hover {
    color: $gray-dark;
    cursor: pointer;
  }

  &.member-element-overlay {
    .member-element-image {
      .member-element-image-layer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        background-color: $primary;
        transition: all 0.6s ease-in-out;
      }
    }

    &:hover {
      .member-element-image {
        .member-element-image-layer {
          opacity: 1;
          z-index: 0;
          transition: all 0.6s ease-in-out;
        }
      }
    }
  }
}
