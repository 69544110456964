section {
  &.hero {
    height: 710px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media screen and (min-width: 992px) {
      height: 840px;
    }

    .hero-video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 0;

      video {
        position: relative;
        display: block;
        width: auto;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .hero-teaser {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-end;
      align-items: flex-start;
      height: 100%;
      padding-bottom: 120px;
      color: $white;

      @media screen and (min-width: 992px) {
        padding-bottom: 195px;
      }

      * {
        z-index: 1;
      }
    }

    &.home-hero {
      @media screen and (min-width: 1400px) {
        height: 1110px;
      }

      h1,
      .h1 {
        @media screen and (min-width: 992px) {
          line-height: 110px;
        }
      }
    }

    .hero-ticker {
      position: relative;
      width: 100%;
      height: 130px;
      overflow: hidden;

      .ticker-item {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        z-index: 0;
        transform: translateY(-100%);
        transition: all 3.8s ease-in-out;
        //transition-delay: 2s;

        &.active {
          opacity: 1;
          z-index: 1;
          transform: translateY(0);
          animation-delay: 0;
          animation-duration: 1.6s;
          animation-fill-mode: none;
          animation-iteration-count: 1;
          animation-name: intro-ticker;
          animation-play-state: running;
          animation-timing-function: ease;
        }
      }
    }
  }
}


.cd-headline {
  font-size: 3rem;
  line-height: 1.2;

  @media only screen and (min-width: 768px) {
    font-size: 4.4rem;
    font-weight: 300;
  }

  @media only screen and (min-width: 1170px) {
    font-size: 6rem;
  }
}

.cd-words-wrapper {
  position: relative;
  display: inline-block;
  text-align: left;
  max-width: 100% !important;

  b {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;
    white-space: nowrap;

    &.is-visible {
      position: relative;
    }
  }
}


.cd-headline {
  &.slide {
    span {
      display: inline-block;
      padding: .2em 0;
    }

    b {
      opacity: 0;
      top: .2em;

      &.is-visible {
        top: 0;
        opacity: 1;
        animation: slide-in 0.6s;
      }

      &.is-hidden {
        animation: slide-out 0.6s;
      }
    }
  }

  .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
  }
}
