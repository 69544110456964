.contact {
  .left {
    position: relative;

    .left-inner {
      position: relative;
      margin-left: -25px;
      margin-right: -25px;

      @media screen and (min-width: 768px) {
        position: absolute;
        width: 999em;
        top: 0;
        bottom: 0;
        right: 25px;
        margin-left: 0;
        margin-right: 0;
        text-align: right;
      }

      img {
        @media screen and (min-width: 768px) {
          width: auto;
          height: 100%;
        }
      }
    }
  }

  .contact-details {
    p {
      margin-bottom: 0;
    }
  }

  .contact-form {
    margin-top: 30px;

    @media screen and (min-width: 768px) {
      margin-top: 50px;
    }
  }

  .contact-icon {
    display: inline-block;
    width: 15px;
  }
}
