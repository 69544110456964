//Colors
$black: #000;
$white: #fff;

$primary: #FF694F;
$secondary: #fff3e7;

$gray-light: #F2F5F5;
$gray-mid2: #bcbcbc;
$gray-mid: #9f9f9f;
$gray-dark: #202020;

$overlay: rgba(#272119, 0.3);

// URIs
$font-base-uri: '../Fonts/';
$image-base-uri: '../Images/';
