section {
  position: relative;
}

.section-primary {
  color: $white;
  background-color: $primary;
}

.section-secondary {
  background-color: $secondary;
}

.section-gray {
  background-color: $gray-light;
}

.section-smallest {
  padding-top: 15px;
  padding-bottom: 15px;

  @media screen and (min-width: 992px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -45px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -60px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -65px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -80px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -115px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -130px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -45px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -60px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -65px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -80px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -115px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -130px !important;
    }
  }
}

.section-smaller {
  padding-top: 25px;
  padding-bottom: 25px;

  @media screen and (min-width: 992px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -55px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -75px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -75px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -95px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -125px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -145px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -55px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -75px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -75px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -95px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -125px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -145px !important;
    }
  }
}

.section-small {
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -60px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -90px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -80px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -110px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -130px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -160px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -60px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -90px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -80px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -110px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -130px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -160px !important;
    }
  }
}

.section-medium {
  padding-top: 35px;
  padding-bottom: 35px;

  @media screen and (min-width: 992px) {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -65px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -115px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -55px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -105px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -135px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -185px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -65px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -115px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -55px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -105px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -135px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -185px !important;
    }
  }
}

.section-big {
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -80px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -130px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -1050px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -150px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -150px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -200px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -80px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -130px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -1050px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -150px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -150px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -200px !important;
    }
  }
}

.section-big2 {
  padding-top: 60px;
  padding-bottom: 60px;

  @media screen and (min-width: 992px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -90px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -150px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -110px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -170px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -160px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -220px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -90px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -150px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -110px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -170px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -160px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -220px !important;
    }
  }
}

.section-bigger {
  padding-top: 75px;
  padding-bottom: 75px;

  @media screen and (min-width: 992px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -105px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -180px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -125px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -200px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -175px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -250px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -105px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -180px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -125px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -200px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -175px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -250px !important;
    }
  }
}

.section-biggest {
  padding-top: 85px;
  padding-bottom: 100px;

  @media screen and (min-width: 992px) {
    padding-top: 170px;
    padding-bottom: 200px;
  }

  .out-top-small {
    @media screen and (min-width: 768px) {
      margin-top: -115px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -200px !important;
    }
  }

  .out-top-medium {
    @media screen and (min-width: 768px) {
      margin-top: -135px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -220px !important;
    }
  }

  .out-top-big {
    @media screen and (min-width: 768px) {
      margin-top: -185px !important;
    }

    @media screen and (min-width: 992px) {
      margin-top: -270px !important;
    }
  }

  .out-bottom-small {
    @media screen and (min-width: 768px) {
      margin-bottom: -115px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -200px !important;
    }
  }

  .out-bottom-medium {
    @media screen and (min-width: 768px) {
      margin-bottom: -135px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -220px !important;
    }
  }

  .out-bottom-big {
    @media screen and (min-width: 768px) {
      margin-bottom: -185px !important;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: -270px !important;
    }
  }
}

.section-center {
  padding-top: 80px;

  @media screen and (min-width: 992px) {
    padding-top: 160px;
  }
}

.section-404 {
  p {
    font-size: 22px;
    line-height: 34px;
  }
}

.section-border-top {
  border-top: 1px solid $gray-dark;
}

.section-border-bottom {
  border-bottom: 1px solid $gray-dark;
}
