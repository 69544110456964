.notare-details {
  position: relative;

  @media screen and (max-width: 575px) {
    padding-bottom: 0;
  }

  .notare-member-column {
    @media screen and (max-width: 575px) {
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: $white;
    }

    @media screen and (min-width: 768px) {
        padding-bottom: 30px;
    }
  }

  &.section-small {
    .notare-intro-image {
      margin-bottom: 30px;

      @media screen and (max-width: 767px) {
        margin-top: -45px;
      }

      @media screen and (min-width: 992px) {
        margin-bottom: 75px;
      }
    }
  }
}

.notare-intro {
  .container {
    position: relative;
  }

  &:not(.notare-empty-publications) {
    &.section-bigger {
      .notare-intro-image {
        @media screen and (min-width: 992px) {
          position: absolute;
          bottom: calc(-50% - 50px);
          right: 25px;
          max-width: 300px;
          z-index: 1;
        }

        @media screen and (min-width: 1200px) {
          bottom: calc(-50% - 150px);
          max-width: 100%;
        }
      }
    }
  }

  &.notare-empty-publications {
    .notare-intro-image {
      @media screen and (min-width: 992px) {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 30px;
        margin-bottom: -75px;
      }
    }
  }
}

.team {
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
