@import "../../../node_modules/bootstrap/scss/bootstrap";

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  @media screen and (min-width: 1400px) {
    max-width: 1300px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1440px;
  }
}

.row {
  margin-left: -25px;
  margin-right: -25px;

  div[class*='col'] {
    padding-left: 25px;
    padding-right: 25px;
  }

  &.no-gutters {
    div[class*='col'] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.container,
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;

  &.container-medium {
    padding-left: 15px;
    padding-right: 15px;

    .row-medium {
      margin-left: -15px;
      margin-right: -15px;

      div[class*='col'] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}
