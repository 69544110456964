.scrolltop {
  position: fixed;
  right: 18px;
  bottom: 90px;
  width: 36px;
  height: 36px;
  background-color: $primary;
  background-image: url('#{$image-base-uri}naa-arrow-up-white.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
}
