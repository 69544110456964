a {
  color: $gray-dark;
  text-decoration: none;

  &:hover {
    color: $primary;
  }

  &:focus {
    color: $gray-dark;
  }
}
