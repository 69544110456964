.carousel-actions {
  position: relative;
  width: 100%;
  height: 32px;
}

.carousel {
  &.carousel-centered {
    .carousel-actions {
      display: inline-block;
      width: 85px;
      height: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.carousel-indicators {
  display: block;
  position: absolute;
  right: 16px;
  bottom: 0;
  left: auto;
  width: 54px;
  height: 16px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  line-height: 14px;
  text-align: center;
  z-index: 2;

  span {
    display: inline-block;
  }

  .current-count {
    box-sizing: border-box;
    display: none;
    flex: none;
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 0;
    margin-left: 0;
    line-height: 14px;
    font-weight: 300;
    text-indent: 0;
    cursor: default;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    &.active {
      display: inline-block;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease-in-out;
    }
  }
}

.carousel-control-next,
.carousel-control-prev,
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 16px
}

.carousel-control-next,
.carousel-control-prev {
  top: auto;
  opacity: 1;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev {
  left: auto;
  right: 70px;
}


.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 16px;
}

.carousel-control-prev-icon {
  background-image: url('#{$image-base-uri}naa-carousel-chevron-left.svg');
}

.carousel-control-next-icon {
  background-image: url('#{$image-base-uri}naa-carousel-chevron-right.svg');
}
