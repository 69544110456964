.accord-item {
  padding-top: 64px;
  padding-bottom: 66px;

  &.accord-primary {
    color: $white;
    background-color: $primary;

    * {
      //color: $white;
    }

    .accord-header {
      a {
        color: $white;
        background-image: url('#{$image-base-uri}naa-chevron-dropup-white.svg') !important;

        &:hover {
          color: rgba($white, 0.65);
        }

        &.collapsed {
          background-image: url('#{$image-base-uri}naa-chevron-dropdown-white.svg') !important;
        }
      }
    }
  }

  &.accord-secondary {
    background-color: $secondary;
  }

  &.accord-gray {
    background-color: $gray-light;
  }
}

.accord-header {
  margin-bottom: 0;

  a {
    display: block;
    background-image: url('#{$image-base-uri}naa-chevron-dropup-primary.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px;

    &.collapsed {
      background-image: url('#{$image-base-uri}naa-chevron-dropdown-primary.svg');
    }
  }
}

.accord-body {
  padding-top: 73px;
  padding-bottom: 9px;
}
